<template>
  <div class="home">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-3">
          <h2 id="greeting">Hey there!</h2>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-6">
              <img src="@/assets/JulianPic.png" />
            </div>
            <div class="col-6">
              <h3>My name is</h3>
              <h2>Julian Zoller</h2>
              <div class="progress" style="height: 10px">
                <div
                  class="progress-bar"
                  id="progress-bar"
                  role="progressbar"
                  aria-valuenow="0"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              <p id="progress-text">I am a student 👨‍🎓 ...</p>
            </div>
          </div>
        </div>
        <div class="col-md-3"></div>
      </div>
    </div>
    <div style="height: 2000px"></div>
  </div>
</template>

<script>
import $ from "jquery";
export default {
  name: "HomeView",
  components: {},
  mounted() {
    this.startProgressBar();
  },
  methods: {
    startProgressBar() {
      let textOptions = [
        "I am a student 👨‍🎓 ...",
        "...I like photography and design 📷 ...",
        "...and on the weekend I am a referee 🤾",
      ];
      var i = 0;
      var bar = document.getElementById("progress-bar");
      var text = document.getElementById("progress-text");
      var textAnimation = $("#progress-text");
      bar.addEventListener("animationiteration", function () {
        textAnimation.fadeOut(function () {
          if (i < 2) {
            text.innerText = textOptions[i + 1];
          } else {
            text.innerText = textOptions[0];
            i = 0;
          }
          i++;
        });

        textAnimation.fadeIn();
      });
    },
  },
};
</script>

<style scoped>
.home {
  margin-top: 100px;
}
#greeting {
  color: white;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 100;
}

img {
  width: 100%;
  margin-top: -30px;
}

h2 {
  color: var(--dark-grey);
}

.progress-bar {
  background-color: var(--blue);
  animation-name: progress-loading;
  animation-duration: 4s;
  animation-timing-function: ease-in;
  animation-iteration-count: infinite;
}

.progress {
  margin: 20px 0 10px 0;
}

@keyframes progress-loading {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

@media only screen and (max-width: 1200px) {
  .col-md-6 {
    min-width: 90vw !important;
    margin: auto;
  }
}

@media only screen and (max-width: 768px) {
  img {
    max-width: 100%;
  }
}
</style>
