<template>
  <nav class="navbar navbar-expand-xl">
    <div class="row">
      <div class="col-md-3" id="JZ">
        <img src="@/assets/JulianPic.png" />
        <h3>Julian Zoller</h3>
      </div>
      <div class="col-md-6" id="nav">
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapseExample"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="collapseExample">
          <div class="navbar-nav">
            <router-link to="/"
              ><a class="nav-item nav-link active" href="#"
                >About me</a
              ></router-link
            >
            <router-link to="/education"
              ><a class="nav-item nav-link" href="/education"
                >Education</a
              ></router-link
            >
            <router-link to="/experience"
              ><a class="nav-item nav-link" href="/experience"
                >Experience</a
              ></router-link
            >
            <router-link to="/skills"
              ><a class="nav-item nav-link" href="/skills"
                >Skills</a
              ></router-link
            >
            <router-link to="/contact"
              ><a class="nav-item nav-link" href="/contact"
                >Contact</a
              ></router-link
            >
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <h1>Who am I?</h1>
      </div>
    </div>
  </nav>
  <RouterView class="router-view" v-slot="{ Component }">
    <Transition name="page-opacity" mode="out-in">
      <component :is="Component" />
    </Transition>
  </RouterView>
</template>

<script>
import { RouterView } from "vue-router";

import $ from "jquery";

export default {
  mounted() {},
  watch: {
    $route(to, from) {
      this.checkRoute();
    },
  },
  methods: {
    checkRoute() {
      var jz = $("#JZ");
      var jzElement = document.getElementById("JZ");
      let route = this.$route.name;
      let width = screen.width;
      jz.fadeOut(function () {
        if (route == "home") {
          jzElement.style.visibility = "hidden";
          if (width < 1200) {
            jzElement.style.height = "0px";
          }
        } else {
          jzElement.style.height = "auto";
          jzElement.style.visibility = "visible";
        }
      });
      jz.fadeIn();
    },
  },
  components: {
    RouterView,
  },
};
</script>

<style>
@import url("https://use.typekit.net/lle7jdr.css");

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("@/assets/Background.png");
  background-repeat: no-repeat;
  background-size: 90%;
  padding: 100px 10% 100px 10%;
  height: 100vh;
}

@media only screen and (min-width: 1920px) {
  .router-view {
    max-width: 1600px;
    margin: auto;
  }

  #app {
    background-size: 80%;
  }
}

@media only screen and (max-width: 1600px) {
  #app {
    background-size: 90%;
  }
}

@media only screen and (max-width: 1400px) {
  #app {
    background-size: 100%;
    padding: 100px 0% 100px 0%;
  }
}

.mobile {
  display: none;
}

@media only screen and (max-width: 1200px) {
  #nav {
    text-align: center;
    margin-top: 25px;
  }
  #app {
    background-size: 0;
  }

  h1 {
    color: white !important;
  }

  .navbar {
    margin-top: -100px;
    margin-bottom: 50px;
    padding: 25px 10% 25px 10% !important;
    background-color: #369eff !important;
  }
}

@media only screen and (max-width: 768px) {
  h1 {
    float: left;
    margin-top: 25px !important;
  }
  .col-md-6 {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  .button-container {
    margin-bottom: 50px;
  }
  img {
    max-width: 100px;
  }
}

:root {
  --font-1: "aller-display", sans-serif;
  --font-2: "montserrat", sans-serif;
  --dark-grey: #707070;
  --blue: #369eff;
}

.page-opacity-enter-active,
.page-opacity-leave-active {
  transition: 400ms ease all;
}

.page-opacity-enter-from,
.page-opacity-leave-to {
  opacity: 0;
  transform: translateY(60px);
}

nav {
  background-color: transparent !important;
  font-family: var(--font-1) !important;
}

nav .row {
  width: 100%;
}

nav a {
  font-weight: bold;
  font-size: 24px !important;
  color: white !important;
  transition: 0.5s !important;
  font-family: var(--font-1);
  text-decoration-line: none !important;
}

nav a:hover {
  opacity: 50%;
}

.router-link-active a {
  color: black !important;
}

h1 {
  font-family: var(--font-1);
  color: var(--dark-grey);
  font-size: 40px !important;
  text-align: right;
}

h2 {
  font-size: 40px !important;
  font-family: var(--font-1);
  line-height: 0.8 !important;
}

h3 {
  font-size: 20px !important;
  font-family: var(--font-1);
  color: var(--dark-grey);
  line-height: 0.8 !important;
}

p {
  font-size: 20px !important;
  font-family: var(--font-2);
}

#JZ {
  justify-content: center;
  margin-top: 10px;
  visibility: hidden;
}

#JZ img {
  width: 100%;
  max-width: 200px;
  display: block;
  margin: auto;
}

#JZ h3 {
  color: white;
  margin: 10px auto 0 auto;
  text-align: center;
}
</style>
